import io, { Socket } from 'socket.io-client'
import { MessageType, NewPlayerLineSendDto } from '../../models'
import { ConnectPlayerDto } from '../../models'
import { ConnectPlayerResponseDto } from '../../models'
import { ReconnectPlayerDto } from '../../models'
import { ReconnectPlayerResponseDto } from '../../models'

const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001'

const socket = (() => {
  let ws: Socket

  const sendMessage = (type: MessageType, body?: any): Promise<any> => {
    return new Promise((resolve) => {
      ws.emit(type, body, (props: any) => {
        resolve(props)
      })
    })
  }

  return {
    initialize(options: SocketOptions) {
      ws = io(SERVER_URL)

      ws.on('connect_error', () => console.log('connect_error'))
      ws.on('connect_timeout', () => console.log('connect_timeout'))
      ws.on('error', () => console.log('error'))
      ws.on('reconnect_error', () => console.log('reconnect_error'))
      ws.on('reconnect_failed', () => console.log('reconnect_failed'))
      ws.on('disconnect', () => console.log('disconnected'))
      ws.on('reconnect', () => console.log('reconnected'))
      ws.on('reconnect_attempt', () => console.log('reconnecting'))

      for (const handler of options.messageHandlers) {
        ws.on(handler.type, handler.handler)
      }
    },
    connectPlayer(body: ConnectPlayerDto): Promise<ConnectPlayerResponseDto> {
      return sendMessage(MessageType.connectPlayer, body)
    },
    reconnectPlayer(body: ReconnectPlayerDto): Promise<ReconnectPlayerResponseDto> {
      return sendMessage(MessageType.reconnectPlayer, body)
    },
    connectOverview(gameId: string): Promise<boolean> {
      return sendMessage(MessageType.connectOverview, gameId)
    },
    sendLine(line: NewPlayerLineSendDto) {
      sendMessage(MessageType.newLine, line)
    },
    sendDebug() {
      sendMessage(MessageType.debug)
    },
    disconnect() {
      ws.close()
    },
  }
})()

type SocketOptions = {
  messageHandlers: { type: MessageType; handler: (payload: any) => any }[]
}

export default socket

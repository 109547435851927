import http from './http'
import api from './api'
import router from './router'
import socket from './socket'
import storage from './storage'

// eslint-disable-next-line
export default {
  http,
  api,
  router,
  socket,
  storage,
}

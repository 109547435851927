import { Player } from './state'

export const mockScores = {
  foo1: 50_000,
  foo2: 100_000,
  foo3: 99_092,
  foo4: 10,
  foo5: 100_000,
  foo6: 10,
  foo7: 253,
  foo8: 1_323,
}

export const mockPlayers: Record<string, Player> = {
  foo1: {
    id: 'foo1',
    name: 'Nisse',
    imageId: 1,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo2: {
    id: 'foo2',
    name: 'Pelle',
    imageId: 2,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo3: {
    id: 'foo3',
    name: 'Tjabo',
    imageId: 3,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo4: {
    id: 'foo4',
    name: 'Greta',
    imageId: 4,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo5: {
    id: 'foo5',
    name: 'Berta',
    imageId: 5,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo6: {
    id: 'foo6',
    name: 'Freddan',
    imageId: 6,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo7: {
    id: 'foo7',
    name: 'Charlie',
    imageId: 7,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo8: {
    id: 'foo8',
    name: 'Coolio',
    imageId: 8,
    state: 'joined',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo9: {
    id: 'foo9',
    name: 'Peter-Niklas',
    imageId: 9,
    state: 'queued',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
  foo10: {
    id: 'foo10',
    name: 'Tess',
    imageId: 10,
    state: 'queued',
    size: { width: 450, height: 450 },
    lines: [],
    connected: true,
  },
}

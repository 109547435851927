type StorageKey = 'player-info'

const storage = {
  saveItem<T>(key: StorageKey, value: T): void {
    if (!localStorage) return
    localStorage.setItem(key, JSON.stringify(value))
  },
  getItem<T>(key: StorageKey): T | null {
    if (!localStorage) return null
    const val = localStorage.getItem(key)
    if (!val) return null
    return JSON.parse(val) as T
  },
  removeItems(...items: StorageKey[]) {
    if (!localStorage) return
    items.forEach((item) => localStorage.removeItem(item))
  },
}

export type CachedPlayerInfo = {
  gameId: string
  playerId: string
  name: string
  imageId: number
  size: {
    width: number
    height: number
  }
}

export default storage
